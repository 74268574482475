.pay_card_box_pool {
  width: calc(calc(100% / 3) - 0.3rem);
  margin-bottom: 0.2rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  box-sizing: border-box;
  height: 6rem;
  border-radius: 0.15rem;
  border: 1px solid #e5e5e5;
  padding: 0.04rem;
  background-image: url("../../../assets/img/game/game_item_bg.png");
  box-shadow: 0px 10px 40px -12px rgba(0, 0, 0, 0.75);

  .pay_card_inline {
    padding: 0.2rem 0 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.15rem;

    .cardTitle {
      margin-bottom: 0.3rem;
      font-size: 0.16rem;
    }

    .top_box {
      display: flex;
      align-items: center;
    }

    .paycard_imgbox {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin-top: 0.3rem;
    }

    .spacex1 {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }

    .spacex2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .marginTop1 {
    margin-top: 0.2rem;
    border: none;
    button{
      width: 1.4rem;
    }
  }
}

@media screen and (max-width: 750px) {
  .pay_card_box_pool {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    .pay_card_inline {
      font-size: 13px;
      .cardTitle {
        font-size: 13px;
      }
      .ant-row {
        .box_item {
          .box_name {
            font-size: 13px;
          }
        }
      }
      .marginTop1 {
        .comm_btn {
          width: 1.1rem;
          height: 0.4rem;
          background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
          border-radius: 0.1rem;
          margin: 0 auto;
          text-align: center;
          line-height: 0.4rem;
          font-size: 0.14rem;
        }
      }
      .paycard_imgbox {
        height: auto;
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);