@import "~antd/dist/antd.css";
html {
  font-size: 625% !important;
  box-sizing: border-box;
}
@media screen and (max-width: 1440px) {
  html {
    font-size: 463.8% !important;
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: 300% !important;
  }
}
@media screen and (max-width: 750px) {
  html {
    font-size: 625% !important;
  }
}
@media screen and (max-width: 500px) {
  html {
    font-size: 400% !important;
  }
}
@media screen and (max-width: 414px) {
  html {
    font-size: 345% !important;
  }
}
@media screen and (max-width: 375px) {
  html {
    font-size: 312.5% !important;
  }
}
div {
  box-sizing: border-box;
}
body,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
img,
br,
hr,
table,
tr,
td,
dl,
dt,
dd,
form {
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
  font-size: 0px;
}
ul,
li {
  list-style: none;
}
img {
  border: none;
}
/*一般链接*/
a {
  text-decoration: none;
  color: #111111;
}
a:hover {
  color: #555;
}
/*清除浮动*/
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}
/* Hides from IE-mac \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* End hide from IE-mac */

/*png css hack for ie6*/

div {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-tooltip-inner {
  border-radius: 16px !important;
}

@font-face {
  font-family: "DEFAULT_FONT";
  src: url("./assets/fonts/MyriadPro-LightSemiCn-18.otf");
}

body {
  margin: 0;
  padding: 0;
  /* font-family: DEFAULT_FONT, PingFang SC-Medium; */
  font-family: PingFang SC-Medium;
  font-size: 0.14rem;
  box-sizing: border-box;
  background: #070f2d;
  color: #fff !important;
}

.ant-layout {
  background: #070f2d;
}

.c-padding-l {
  padding-left: 0.2rem;
}

.comm_btn {
  color: white;
  padding: 0.09rem 0.15rem;
  background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
  border-radius: 6px;
  background-size: 100% 100%;
  font-size: 0.18rem;
  text-align: center;
}
.space {
  margin-left: 0.3rem;
}

button {
  background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%) !important;
  border: none !important;
  color: #fff !important;
  padding: 0 !important;
}
button:active {
  background: #6f92fe !important;
}
