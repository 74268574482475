.header {
  height: 0.8rem;
  background-color: #2a215a;
  color: #fff;
  .header_con {
    width: 15rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .header_left {
      display: flex;
      align-items: center;
      .header_router {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 0.16rem;
        margin-right: 0.4rem;
        > img {
          height: 0.2rem;
          margin-right: 0.06rem;
        }
        .text {
          line-height: 0.2rem;
        }

        &:first-child {
          margin-right: 0.8rem;
          > img {
            height: 0.24rem;
            margin-right: 0.1rem;
          }
        }
      }
    }
    .header_left_m {
      display: none;
    }
    .header_right {
      display: flex;
      align-items: center;
      .connected {
        padding: 0 0.1rem;
        height: 0.42rem;
        display: flex;
        align-items: center;
        font-size: 0.14rem;
        background-color: #11193d;
        border-radius: 0.1rem;
        margin-right: 0.15rem;
        color: #fff;
        > img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.14rem;
        }
        .text {
          line-height: 0.16rem;
        }
      }

      .lang {
        height: 0.42rem;
        padding: 0 0.15rem;
        border: 1px solid #615afc;
        border-radius: 0.1rem;
        display: flex;
        align-items: center;
        margin-right: 0.15rem;
        > img {
          width: 0.18rem;
          height: 0.18rem;
          margin-right: 0.06rem;
        }
        .text {
          font-size: 0.14rem;
          line-height: 0.16rem;
        }
      }
      .link_wallet {
        min-width: 1.2rem;
        height: 0.42rem;
        padding: 0 0.1rem;
        background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
        border-radius: 0.1rem;
        line-height: 0.42rem;
        font-size: 0.16rem;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.header_right_list {
  background-color: #2a215a;
  border: 1px solid #615afc;
  border-radius: 0.1rem;
  > .item {
    padding-left: 0.2rem;
    line-height: 0.4rem;
    font-size: 0.14rem;
    color: #fff;
    cursor: pointer;
    > span {
      &:hover {
        color: #615afc;
        border-bottom: 2px solid #615afc;
      }
    }
  }
}

.link_wallet_dialog {
  background: linear-gradient(133deg, #3e4247 0%, #2e3135 100%);
  width: 3.6rem !important;
  border-radius: 0.15rem;
  padding-bottom: 0;

  .ant-modal-header {
    background-color: transparent;
    .ant-modal-title {
      color: #fff;
    }
  }

  .ant-modal-content {
    background-color: transparent;
    width: 3.6rem;
  }

  .farms_modal_box {
    width: 100%;
    height: 0.5rem;
    background-color: #ffab0f;
    margin-bottom: 0.1rem;
    border-radius: 0.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem;
    font-size: 0.16rem;
    color: #fff;

    img {
      width: 0.3rem;
      height: 0.3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 60px;
    z-index: 9999;
    .header_con {
      width: 100%;
      padding: 0 14px;
      .header_left {
        display: none;
      }
      .header_left_m {
        display: flex;
        align-items: center;
        font-size: 24px;
      }
      .header_right {
        .connected {
          width: 90px;
          height: 40px;
          > img {
            width: 24px;
            height: 24px;
          }
          .text {
            font-size: 14px;
          }
        }
        .lang_drop {
          display: none;
        }
        .link_wallet {
          min-width: 90px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }
    }
  }
  .menu_drawer {
    margin-top: 60px;
    .ant-drawer-content-wrapper {
      height: auto !important;
    }
    .ant-drawer-body {
      padding: 0;
      background-color: #11193d;
      .menu {
        .menu_router {
          width: 100%;
          height: 60px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #070f2d;
          > img {
            width: 20px;
            height: auto;
            margin-right: 0.1rem;
          }
          .text {
            font-size: 16px;
            color: #fff;
          }
        }

        .lang {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          > img {
            width: 20px;
            height: auto;
            margin-right: 0.1rem;
          }
          .text {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
  }
  // .ant-dropdown {
  //   min-width: 95% !important;
  //   left: 50% !important;
  //   transform: translateX(-50%);
  // }
  .header_right_list {
    > .item {
      line-height: 0.6rem;
      font-size: 12px;
    }
  }
  .link_wallet_modal {
    width: 90% !important;
    .ant-modal-body {
      padding: 0.5rem;
      .title {
        font-size: 16px;
      }
      .box {
        margin: 30px 0;
        cursor: pointer;
        > img {
          width: 100px;
          height: 100px;
        }
        > span {
          font-size: 16px;
          margin-top: 20px;
        }
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);