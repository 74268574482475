.frame {
  width: 100%;
  min-height: 100vh;
  background-size: 100% auto;
  position: relative;
  padding-bottom: 0.4rem;
}

// .ant-layout-content {
//   padding-bottom: 1rem;
// }

@media screen and (max-width: 768px) {
  .frame {
    .frame_img {
      width: 100%;
      height: 100px;
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);