.boxBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .boxHeader {
    margin-top: 0.8rem;
    margin-bottom: 0.6rem;
    width: 5rem;
    height: 0.5rem;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../../assets/img/game/game_title_bg.png");
    display: flex;
    justify-content: center;
    .headerTitle {
      display: flex;
      font-size: 0.18rem;
      font-weight: 600;
      color: #ffffff;
      align-items: center;
      margin-top: 0.4rem;
      .headerQ {
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.05rem;
        cursor: pointer;
      }
    }
  }
  .pay_card_warp {
    margin: 0 auto;
    width: 90%;
    margin-top: 0.47rem;

    .pay_card_content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

.farms_modal {
  background: linear-gradient(133deg, #3e4247 0%, #2e3135 100%);
  width: 3.6rem !important;
  border-radius: 0.15rem;
  padding-bottom: 0;

  .ant-modal-content {
    background-color: transparent;
    width: 3.6rem;
    height: 4rem;
  }

  .ant-modal-header {
    background-color: transparent;
    .ant-modal-title {
      color: #fff;
    }
  }

  .farms_modal_box {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .open_box_amount{
    margin-top: .2rem;
    width: 100%;
    text-align: center;
    color: white;
  }
}

@media screen and (max-width: 750px) {
  .boxBody {
    .boxHeader {
      width: 100%;
      .headerTitle {
        font-size: 16px;
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);