.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.baseBtn {
  width: 110px;
  height: 40px;
  background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
  border-radius: 10px;
}

.in_page {
  width: 15rem;
  padding: 0 0.2rem;
  padding-bottom: 0.3rem;
  color: white;
  font-size: 0.24rem;
  margin: 0 auto;
  .swap_area {
    width: 8rem;
    background: #242666;
    padding: 0.3rem;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0 auto;

    .dashboard_data {
      background-color: #000000;
      padding: 12px;
      border-radius: 10px;
      box-sizing: border-box;
      .s-top {
        margin-top: 21px;
      }
      .flexStart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //margin-bottom: 21px;
        .item-img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          background-position: 0% 0%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .u-line-1-add {
          //flex: 1 1 0%;
          margin-left: 5px;
          //color: rgb(62, 111, 250);
          display: -webkit-box !important;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical !important;
        }
      }

      .input-area {
        background-color: black;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.1rem;
        .u-input {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          background-color: #242666;
          border: 1px solid white;
          border-radius: 8px;
          .buy_inout {
            text-align: right;
            color: white;
          }
        }
        .USDT {
          padding-top: 0.1rem;
          padding-right: 0.2rem;
          width: 100%;
          text-align: right;
        }
      }
    }

    .switch-area {
      display: flex;
      justify-content: center;

      .switch-img {
        margin: 10px;
        width: 25px;
        height: 25px;
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .s-huadian {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 0.2rem;
      padding-right: 0.35rem;
    }

    .ant-slider {
      margin-top: 0.2rem;
      height: 0.2rem;
    }

    .handle-row {
      display: flex;
      justify-content: center;
      margin-top: 0.3rem;

      .comm_btn {
        width: 2rem;
      }
    }
  }

  .log-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0px;
    .item-img {
      width: 1rem;
      margin-top: 0.5rem;
    }
    .logo-name {
      margin-top: 0.2rem;
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (max-width: 768px) {

  .in_page{
    width: 100%;
    .swap_area{
      width: 100%;
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);