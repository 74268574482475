.home {
  width: 15rem;
  margin: 0 auto;
  .cast_on_outwell_box {
    height: 1.85rem;
    padding-top: 0.4rem;
    display: flex;
    justify-content: center;

    .outwell_left {
      display: flex;
      .icon {
        width: 0.97rem;

        .icon_top {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          background: linear-gradient(90deg, #332a6d 0%, #222665 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 0.05rem;
        }
        .icon_bom {
          width: 100%;
          height: 0.5rem;
          background: url("../../../assets/img/scy/shadow.png");
          background-size: 100% 100%;
        }
      }
      .balance {
        height: 0.6rem;
        margin-left: 0.15rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .balance_text {
          font-size: 0.14rem;
        }
        .balance_num {
          font-size: 0.24rem;
          color: #7899ff;
          font-weight: bold;
        }
        .outwell_con_m {
          display: none;
        }
      }
    }
    .outwell_con {
      margin: 0 0.8rem;
      .text {
        font-size: 0.14rem;
        margin-bottom: 0.1rem;
      }
      .outwell_progress {
        .ant-progress-inner {
          width: 2rem;
          height: 0.11rem;
        }
        .ant-progress-bg {
          height: 0.11rem !important;
        }
      }
    }
    .outwell_right {
      .outwell_right_content {
        display: flex;
        align-items: center;
        .outwell_btn {
          min-width: 1.5rem;
          padding: 0 0.1rem;
          height: 0.42rem;
          border-radius: 0.1rem;
          text-align: center;
          line-height: 0.42rem;
          font-size: 0.16rem;
          margin-right: 0.2rem;
          cursor: pointer;
        }
        > img {
          width: 0.18rem;
          height: 0.18rem;
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 0.06rem;
    background: url("../../../assets/img/scy/line.png");
    background-size: 100% 100%;
  }
  .be_worth {
    width: 100%;
    height: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .be_worth_box {
      display: flex;
      height: 0.6rem;
      min-width: 3rem;
      .be_worth_box_icon {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        border: 1px solid #5b44fb;
        margin-right: 0.32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 0.26rem;
          height: 0.26rem;
        }
      }
      .be_worth_box_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .text {
          font-size: 0.14rem;
        }
        .num {
          font-size: 0.24rem;
          font-weight: bold;
          color: #7899ff;
        }
      }
    }
  }
  .my_info {
    width: 100%;
    height: 2.7rem;
    background: url("../../../assets/img/scy/bg.png");
    background-size: 100% 100%;
    padding: 0.26rem 0;
    .title {
      text-align: center;
      font-size: 0.16rem;
    }
    .info_box {
      width: 100%;
      height: 1.37rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .be_worth_box {
        display: flex;
        height: 0.6rem;
        min-width: 3rem;
        .be_worth_box_icon {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          border: 1px solid #5b44fb;
          margin-right: 0.32rem;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 0.26rem;
            height: 0.26rem;
          }
        }
        .be_worth_box_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .text {
            font-size: 0.14rem;
          }
          .num {
            font-size: 0.24rem;
            font-weight: bold;
            color: #7899ff;
          }
        }
      }
    }
    .btn_box {
      display: flex;
      justify-content: center;
      .jiantou_left,
      .jiantou_right {
        display: none;
      }
      .btn {
        width: 1.1rem;
        height: 0.42rem;
        background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
        border-radius: 0.1rem;
        margin: 0 auto;
        text-align: center;
        line-height: 0.42rem;
        font-size: 0.16rem;
        cursor: pointer;
      }
    }
  }
  .combustion {
    margin-top: 0.4rem;
    width: 100%;
    height: 3.61rem;
    background: url("../../../assets/img/scy/bg2.png");
    background-size: 100% 100%;
    position: relative;
    .combustion_top {
      width: 100%;
      height: 1.54rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3.3rem;
      .be_worth_box {
        display: flex;
        height: 0.6rem;
        min-width: 3rem;
        .be_worth_box_icon {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          border: 1px solid #5b44fb;
          margin-right: 0.32rem;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 0.26rem;
            height: 0.26rem;
          }
        }
        .be_worth_box_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .text {
            font-size: 0.14rem;
          }
          .num {
            font-size: 0.24rem;
            font-weight: bold;
            color: #7899ff;
          }
        }
      }
    }

    .combustion_input {
      width: 10.4rem;
      height: 0.4rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-bottom: 0.15rem;
      .tit {
        width: 1.7rem;
        font-size: 0.16rem;
      }
      .text {
        color: white;
        flex: 1;
        height: 0.4rem;
        background: #11193d;
        border-radius: 0.1rem;
        padding: 0 0.12rem;
        line-height: 0.4rem;
        font-size: 0.14rem;
      }
    }
    .btn_box {
      display: flex;
      justify-content: center;
      .jiantou_left,
      .jiantou_right {
        display: none;
      }
      .btn {
        width: 1.1rem;
        height: 0.42rem;
        background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
        border-radius: 0.1rem;
        margin: 0 auto;
        margin-top: 0.15rem;
        text-align: center;
        line-height: 0.42rem;
        font-size: 0.16rem;
      }
    }

    .prompt {
      width: 0.2rem;
      height: 0.22rem;
      border-radius: 50%;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }
  }
  .bind_invitation {
    width: 100%;
    height: 7rem;
    border: 1px solid #44a2b9;
    border-radius: 0.1rem;
    margin-top: 0.4rem;
    position: relative;
    .totle_invitation {
      margin-top: 0.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.3rem;
      .title {
        font-size: 0.16rem;
      }
      .num {
        font-size: 0.24rem;
        color: #7899ff;
        margin-top: 0.1rem;
        font-weight: bold;
      }
    }
    .combustion_top_bg {
      width: 6.5rem;
      height: 0.46rem;
      background: url("../../../assets/img/scy/combustion.png");
      background-size: 100% 100%;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .input_info {
      width: 10.4rem;
      height: 0.4rem;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 0.3rem;
      .tit {
        width: 1.7rem;
      }
      .text {
        flex: 1;
        height: 100%;
        background: #11193d;
        border-radius: 0.1rem;
        padding: 0 0.12rem;
        line-height: 0.4rem;
        font-size: 0.14rem;
        margin-right: 0.3rem;
        color: white;
      }
      .btn {
        width: 1.1rem;
        height: 100%;
        background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
        border-radius: 0.1rem;
        text-align: center;
        line-height: 0.4rem;
        font-size: 0.14rem;
        cursor: pointer;
      }
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
    .bind_invitation_top {
      width: 100%;
      height: 1.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3.3rem;
      .be_worth_box {
        display: flex;
        height: 0.6rem;
        min-width: 3rem;
        .be_worth_box_icon {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          //border: 1px solid #5b44fb;
          margin-right: 0.32rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .be_worth_box_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .text {
            font-size: 0.14rem;
          }
          .num {
            font-size: 0.24rem;
            font-weight: bold;
            color: #7899ff;
          }
        }
      }
    }
    .invite_list {
      width: 8rem;
      height: 2.9rem;
      border: 1px solid #44a2b9;
      border-radius: 0.1rem;
      margin: 0 auto;
      padding: 0.16rem;
      .invite_list_box {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0.06rem;
          height: 100%;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0.03rem;
          background: #6d8cfe;
        }
        &::-webkit-scrollbar-track {
          border-radius: 0.03rem;
          background: #2a215a;
        }
        .invite_list_item {
          font-size: 0.14rem;
          line-height: 0.2rem;
        }
      }
    }
  }
}

// 弹窗
.my_inviter_modal {
  width: 6.6rem !important;
  .ant-modal-content {
    background: transparent;
    .ant-modal-body {
      height: 2rem;
      padding: 0.18rem;
      background: linear-gradient(90deg, #332a6d 0%, #222665 100%);
      border-radius: 0.1rem;
      color: #fff;
      font-size: 0.16rem;
      .top {
        position: relative;
        .title {
          text-align: center;
          font-weight: bold;
        }
        .clone_icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 0.24rem;
          height: 0.24rem;
          cursor: pointer;
        }
      }
      .input_info {
        display: flex;
        align-items: center;
        margin: 0.2rem 0 0.3rem 0;
        .tit {
          width: 1.1rem;
        }
        .text {
          width: 4.9rem;
          height: 0.4rem;
          background: #11193d;
          border-radius: 0.1rem;
          line-height: 0.4rem;
          // display: flex;
          // flex-wrap: nowrap;
          // overflow-x: scroll;
          overflow: hidden;
          padding: 0 0.1rem;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .binding {
        width: 1.1rem;
        height: 0.4rem;
        background: linear-gradient(313deg, #5839fb 0%, #6f92fe 100%);
        border-radius: 0.1rem;
        margin: 0 auto;
        text-align: center;
        line-height: 0.4rem;
        font-size: 0.14rem;
        cursor: pointer;
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    width: 90%;
    .cast_on_outwell_box {
      height: auto;
      display: flex;
      flex-direction: column;
      .outwell_left {
        height: auto;
        .icon {
          width: 93px;
          .icon_top {
            width: 57px;
            height: 57px;
          }
          .icon_bom {
            width: 93px;
            height: 48px;
          }
        }
        .balance {
          width: 190px;
          height: auto;
          margin-left: 20px;
          .balance_text {
            font-size: 13px;
          }
          .balance_num {
            font-size: 23px;
          }
          .outwell_con_m {
            display: block;
            .text {
              font-size: 13px;
              margin-bottom: 0.1rem;
            }
            .outwell_progress {
              .ant-progress-inner {
                width: 191px;
                height: 10px;
              }
              .ant-progress-bg {
                height: 10px !important;
              }
            }
          }
        }
      }
      .outwell_con {
        display: none;
      }
      .outwell_right {
        .outwell_right_content {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          .outwell_btn {
            min-width: 210px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
          }
          > img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .line {
      margin-top: 30px;
    }
    .be_worth {
      height: auto;
      flex-direction: column;
      margin: 30px 0;
      padding: 0;
      .be_worth_box {
        height: auto;
        width: 100%;
        min-width: none;
        padding-left: 30px;
        display: flex;
        align-items: center;

        .be_worth_box_icon {
          width: 57px;
          height: 57px;
          margin-right: 20px;
          > img {
            width: 25px;
            height: 25px;
          }
        }
        .be_worth_box_info {
          flex: 1;
          .text {
            font-size: 14px;
          }
          .num {
            font-size: 24px;
          }
        }
        &:nth-child(2) {
          margin: 20px 0;
          padding-left: 30px;
        }
      }
    }
    .my_info {
      width: 100%;
      height: 384px;
      margin: 0 auto;
      background: #312a6c;
      border-radius: 10px;
      .title {
        font-size: 15px;
        font-weight: 700;
      }
      .info_box {
        height: auto;
        flex-direction: column;
        margin: 30px 0;
        padding: 0;
        .be_worth_box {
          height: 57px;
          width: 100%;
          min-width: none;
          padding-left: 30px;
          display: flex;
          align-items: center;
          .be_worth_box_icon {
            width: 57px;
            height: 57px;
            margin-right: 20px;
            > img {
              width: 25px;
              height: 25px;
            }
          }
          .be_worth_box_info {
            flex: 1;
            .text {
              font-size: 14px;
            }
            .num {
              font-size: 24px;
            }
          }
          &:nth-child(2) {
            margin: 20px 0;
            padding-left: 30px;
          }
        }
      }
      .btn_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 18px;
        .jiantou_left {
          display: block;
          width: 77px;
          height: 22px;
          background: url("../../../assets/img/scy/shadowleft.png");
          background-size: 100% 100%;
        }
        .btn {
          width: 105px;
          height: 38px;
          line-height: 38px;
          font-size: 15px;
          margin: 0;
        }
        .jiantou_right {
          display: block;
          width: 77px;
          height: 22px;
          background: url("../../../assets/img/scy/shadowright.png");
          background-size: 100% 100%;
        }
      }
    }
    .combustion {
      width: 100%;
      height: 510px;
      margin: 0 auto;
      margin-top: 20px;
      background: #312a6c;
      border-radius: 10px;
      padding-top: 25px;
      .combustion_top {
        width: 100%;
        height: auto;
        flex-direction: column;
        margin: 30px 0;
        padding: 0;
        .be_worth_box {
          height: 57px;
          min-width: 0;
          width: 100%;
          padding-left: 30px;
          display: flex;
          align-items: center;
          .be_worth_box_icon {
            width: 57px;
            height: 57px;
            margin-right: 20px;
            > img {
              width: 25px;
              height: 25px;
            }
          }
          .be_worth_box_info {
            flex: 1;
            .text {
              font-size: 14px;
            }
            .num {
              font-size: 24px;
            }
          }
          &:nth-child(2) {
            margin: 20px 0;
            padding-left: 30px;
          }
        }
      }
      .combustion_input {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        .tit {
          width: 100%;
          text-align: center;
          font-size: 15px;
        }
        .text {
          width: 310px;
          height: auto;
          padding: 10px;
          line-height: 20px;
          font-size: 14px;
          margin-top: 10px;
          word-wrap: break-word;
        }
      }
      .btn_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 18px;
        margin-top: 20px;
        .jiantou_left {
          display: block;
          width: 77px;
          height: 22px;
          background: url("../../../assets/img/scy/shadowleft.png");
          background-size: 100% 100%;
        }
        .btn {
          width: 105px;
          height: 38px;
          line-height: 38px;
          font-size: 15px;
          margin: 0;
        }
        .jiantou_right {
          display: block;
          width: 77px;
          height: 22px;
          background: url("../../../assets/img/scy/shadowright.png");
          background-size: 100% 100%;
        }
      }
      .prompt {
        width: 17px;
        height: 17px;
        background: url("../../../assets/img/scy/prompt.png");
        background-size: 100% 100%;
        top: 20px;
        right: 20px;
      }
    }
    .bind_invitation {
      background: transparent;
      height: auto;
      border-radius: 10px;
      padding: 0 18px;
      .totle_invitation {
        margin-top: 30px;
        margin-bottom: 20px;
        .title {
          font-size: 15px;
          font-weight: 700;
        }
        .num {
          font-size: 23px;
        }
      }
      .combustion_top_bg {
        display: none;
      }
      .input_info {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        .tit {
          width: 100%;
          text-align: center;
          font-size: 15px;
        }
        .text {
          width: 310px;
          height: auto;
          padding: 10px;
          line-height: 20px;
          font-size: 14px;
          margin-top: 10px;
          word-wrap: break-word;
          margin-right: 0;
        }
        .btn {
          width: 105px;
          height: 38px;
          border-radius: 10px;
          font-size: 13px;
          line-height: 38px;
          margin-top: 20px;
        }
      }
      .bind_invitation_top {
        height: auto;
        margin: 30px 0;
        padding: 0;
        justify-content: space-between;
        .be_worth_box {
          height: 57px;
          width: auto;
          min-width: auto;
          margin-left: 0px;
          display: flex;
          align-items: center;
          .be_worth_box_icon {
            width: 57px;
            height: 57px;
            margin-right: 10px;
            > img {
              width: 25px;
              height: 25px;
            }
          }
          .be_worth_box_info {
            flex: 1;
            .text {
              font-size: 14px;
            }
            .num {
              font-size: 24px;
            }
          }
          &:nth-child(2) {
            margin: 0;
            margin-left: 0;
          }
        }
      }
      .invite_list {
        width: 100%;
        height: 300px;
        border: none;
        padding: 0;
        margin-bottom: 20px;
        .invite_list_box {
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 3px;
          }
          .invite_list_item {
            font-size: 13px;
            line-height: 20px;
            word-wrap: break-word;
            margin-bottom: 6px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .my_inviter_modal {
    width: 90% !important;
    .ant-modal-content {
      .ant-modal-body {
        height: auto;
        padding: 10px;
        border-radius: 10px;
        font-size: 14px;
        .top {
          .title {
            font-size: 16px;
          }
          .clone_icon {
            width: 20px;
            height: 20px;
          }
        }
        .input_info {
          margin: 20px 0;
          font-size: 14px;
          padding: 0 20px;
        }
        .binding {
          width: auto;
          min-width: 100px;
          height: 38px;
          border-radius: 10px;
          line-height: 38px;
          font-size: 14px;
          padding: 0 10px !important;
          cursor: pointer;
          display: block;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);