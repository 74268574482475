.EconomicPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  .economicpage_top {
    width: 14rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 0.36rem;
    .fomofi {
      width: 4.5rem;
      background-color: #27337c;
      border-radius: 0.1rem;
      padding: 0.3rem 0.2rem;
      .title {
        font-size: 0.18rem;
        font-weight: bold;
        margin-bottom: 0.3rem;
      }
      ul > li {
        font-size: 0.14rem;
        list-style: inside;
        line-height: 0.4rem;
      }
      .address {
        margin-top: 0.2rem;
        font-size: 0.16rem;
        font-weight: 700;
        .address_input {
          margin-top: 0.1rem;
          color: white;
          width: 100%;
          height: auto;
          background: #11193d;
          border-radius: 0.1rem;
          padding: 0.12rem;
          line-height: 0.2rem;
          font-size: 0.14rem;
          word-wrap: break-word;
        }
      }
    }
    .headArea {
      width: 9rem;
      display: flex;
      flex-direction: column;
      padding: 0.26rem 0.4rem 0.6rem;
      background-color: #27337c;
      justify-content: center;
      align-items: center;
      border-radius: 0.1rem;
      .ruleHeader {
        margin-top: 0.2rem;
        margin-bottom: 0.4rem;
        width: 4rem;
        background-repeat: no-repeat;
        background-size: 100%;
        background-image: url("../../../assets/img/game/game_title_bg.png");
        justify-content: center;
        .headerTitle {
          display: flex;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          align-items: center;
          justify-content: space-between;
          .headerQ {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }
      .ruleContent {
        .space {
          margin-top: 0.1rem;
        }
        .title {
        }
        .text {
        }
      }
    }
  }

  .EconomicTxtArea {
    margin: 0 auto;
    width: 90%;
    margin-top: 0.74rem;

    .EconomicTitle {
      width: 100%;
      text-align: center;
      font-size: 0.33rem;
      margin-bottom: 0.3rem;
    }
    .EconomicContent {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .EconomicItem {
        width: calc(calc(100% / 3) - 0.3rem);
        margin-bottom: 0.2rem;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        box-sizing: border-box;
        border-radius: 0.05rem;
        border: 1px solid #e5e5e5;
        background-color: #27337c;
        box-shadow: 0px 10px 40px -12px rgba(0, 0, 0, 0.75);
        padding: 0.2rem;
        .title {
          font-size: 0.18rem;
        }
        .desc > li {
          font-size: 0.12rem;
          list-style: inside;
          line-height: 0.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .EconomicPage {
    .economicpage_top {
      width: 90%;
      flex-direction: column;
      .fomofi {
        width: 100%;
        margin-bottom: 30px;
        .title {
          font-size: 15px;
        }
        ul > li {
          font-size: 13px;
        }
        .address {
          font-size: 14px;
          .address_input {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      .headArea {
        width: 100%;
        border-radius: 10px;
        padding: 16px;
        .ruleHeader {
          .headerTitle {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .ruleContent {
          .space {
            margin: 0;
            font-size: 13px;
            margin-bottom: 6px;
          }
        }
      }
    }

    .EconomicTxtArea {
      .EconomicContent {
        .EconomicItem {
          width: 100%;
          .title {
            font-size: 16px;
          }
          .desc > li {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);